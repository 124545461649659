import { PresenceBadgeStatus, TableRowId } from "@fluentui/react-components";
import { HealthCheckSetting, Impact, NumberCompare, Priority } from "../service/JiscM365CsModelsModel";
export type IHealthCheckType = "#Jisc.M365CS.Models.HealthCheckCountSetting" | "#Jisc.M365CS.Models.HealthCheckValueSetting";
export type HealthCheckRowItemType = "SharePoint"|"Other"|"GroupLicense"|"GraphRecommendation"|"PolicyRecommendation"|undefined;

export abstract class HealthCheckBase implements HealthCheckSetting {
    public abstract "@odata.type"?: IHealthCheckType;
    constructor(value?: HealthCheckBase) {
        this.name = this.displayName = this.reason = this.id = "";
        this.priority = this.impact = this.moreInfoLink = this.createdBy = this.created = this.modified = this.modifiedBy = this.category = null;
        if (value) {
            this.created = value.created;
            this.createdBy = value.createdBy;
            this.displayName = value.displayName;
            this.id = value.id;
            this.impact = value.impact;
            this.modified = value.modified;
            this.modifiedBy = value.modifiedBy;
            this.moreInfoLink = value.moreInfoLink;
            this.name = value.name;
            this.priority = value.priority;
            this.reason = value.reason;
            this.category = value.category;
        }
    }
    id: string;
    name: string;
    displayName: string | null;
    reason: string;
    priority: Priority | null;
    impact: Impact | null;
    moreInfoLink: string | null;
    createdBy: string | null;
    created: string | null;
    modifiedBy: string | null;
    modified: string | null;
    category: string | null;
}

export class HealthCheckCountSetting extends HealthCheckBase {
    public count: number;
    public compare: NumberCompare;
    public "@odata.type": IHealthCheckType = "#Jisc.M365CS.Models.HealthCheckCountSetting";
    public constructor(value?: HealthCheckBase|HealthCheckCountSetting, count?: number, compare?: NumberCompare) {
        super(value);
        this.count = 0;
        this.compare = NumberCompare.Equals;
        if (value instanceof HealthCheckCountSetting) {
            this.count = value.count;
            this.compare = value.compare;
        } else {
            if ((value as any).count !== undefined) this.count = (value as any).count;
            if ((value as any).compare !== undefined) this.compare = (value as any).compare;
        }
        if (count) this.count = count;
        if (compare) this.compare = compare;
    }
}

export class HealthCheckValueSetting<T> extends HealthCheckBase {
    public value?: T;
    public not?: boolean;
    public "@odata.type": IHealthCheckType = "#Jisc.M365CS.Models.HealthCheckValueSetting";
    constructor(value?: HealthCheckBase|HealthCheckValueSetting<T>, not?: boolean, v?: T|unknown) {
        super(value);
        this.not = false;
        if (value instanceof HealthCheckValueSetting) {
            this.not = value.not;
            this.value = value.value;
        } else {
            if ((value as any).not !== undefined) this.not = (value as any).not;
            if ((value as any).value !== undefined) this.value = (value as any).value;
        }
        if (not !== undefined) this.not = not;
        if (v !== undefined) this.value = v as T;
    }
}

export const HCCheck = <T>(setting: HealthCheckCountSetting | HealthCheckValueSetting<T>|undefined, value: T, fallback?: PresenceBadgeStatus): PresenceBadgeStatus => {
    try {
        if (typeof value === "string" && value.match(/True|False/gi)) value = JSON.parse(value.toLowerCase()) as T;
        if (setting?.["@odata.type"] === "#Jisc.M365CS.Models.HealthCheckCountSetting") setting = new HealthCheckCountSetting(setting);
        if (setting === undefined) return fallback ?? "unknown";
        else if (setting instanceof HealthCheckCountSetting) {
            switch (setting.compare) {
                case "LessThan": return value as number < setting.count ? "available" : "do-not-disturb";
                case "LessThanEquals": return value as number  <= setting.count ? "available" : "do-not-disturb";
                case "Equals": return value as number  === setting.count ? "available" : "do-not-disturb";
                case "GreaterThanEquals": return value as number  >= setting.count ? "available" : "do-not-disturb";
                case "GreaterThan": return value as number  > setting.count ? "available" : "do-not-disturb";
                case "NotEquals": return value as number  !== setting.count ? "available" : "do-not-disturb";
                default: return fallback ?? "unknown";
            }
        } else if (typeof setting.value === "object" && Array.isArray(setting.value) && !Array.isArray(value)) {
            const incs = (setting.value as unknown[]).includes(value ?? "null");
            const incsString = (setting.value as unknown[]).find(v => (v as object).toString() === ((value ?? "null") as object).toString());
            const find = (setting.value as unknown[]).find(v => JSON.stringify(v) === JSON.stringify(value ?? "null"));
            return (incs || incsString || find) ? (setting.not ? "do-not-disturb" : "available") : "do-not-disturb";
        } else if (typeof value === "object" && !Array.isArray(value) && typeof setting.value === "string") 
            return JSON.stringify(value) === decodeURI(setting.value) ? (setting.not ? "do-not-disturb" : "available") : "do-not-disturb";
        else if ((Array.isArray(setting.value) && Array.isArray(value)) || ((typeof setting.value === "object" && typeof value === "object"))) 
            return JSON.stringify(setting.value).toLowerCase() === JSON.stringify(value).toLowerCase() ? (setting.not ? "do-not-disturb" : "available") : "do-not-disturb";
        else if (setting.value === "null") return value === null || value === undefined || value === "" || value === "null" || value === "$null" ? (setting.not ? "do-not-disturb" : "available") : "do-not-disturb";
        else return setting.value === value ? (setting.not ? "do-not-disturb" : "available") : "do-not-disturb";
    } catch {
        return fallback ?? "unknown";
    }
};

export interface IHealthCheckPageProps {
    loading: boolean;
    setLoading: (v: boolean) => void;
    getSetting: (name: string) => HealthCheckBase | undefined;
}

export interface IHealthCheckRowItem {
    id: string;
    displayName: string;
    value?: any;
    setting?: HealthCheckBase;
    type?: HealthCheckRowItemType;
    state?: PresenceBadgeStatus;
}

export interface IWrappedHealthCheckRowItem {
    settings: IHealthCheckRowItem[];
    displayName: string;
}

export interface ISelectedHealthCheckRowItem extends IHealthCheckRowItem {
    root: string;
    selection: TableRowId;
}

export interface IFilter {
    priority: Priority[];
    impact: Impact[];
    recsOnly: boolean;
    areas: string[];
}