import { Providers, prepScopes } from "@microsoft/mgt-element";
import { Client, GraphRequest } from "@microsoft/microsoft-graph-client";
import { FetchClient } from "@odata2ts/http-client-fetch";
import { Tenant } from "../service/JiscM365CsModelsModel";
import { JiscM365CsModelsService } from "../service/JiscM365CsModelsService";

export class JTool {
    readonly tenant?: Tenant;
    readonly client?: Client;
    jservice?: JiscM365CsModelsService<FetchClient>;
    constructor(Tenant?: Tenant, Client?: Client, Bearer?: string, ToasterId?: string) {
        this.tenant = Tenant;
        this.client = Client;
        this.toasterId = ToasterId;
        if (Bearer) this.jservice = new JiscM365CsModelsService(new FetchClient(Bearer ? {
            headers: { "Authorization": `Bearer ${Bearer}` }
        } : {}), `https://${Providers.globalProvider.customHosts!.at(0)}/v1.0`)
    }
    toasterId?: string;
    jtool = (path: string): GraphRequest => this.client!.api(this.makePath(path)).middlewareOptions(prepScopes(["api://820855a8-604e-45e3-842c-73081b0396cc/user"]));
    makePath = (path: string, noV1 = false): string => path.startsWith("http") ? path : (`https://${Providers.globalProvider.customHosts!.at(0)}${noV1 ? "" : "/v1.0"}${path.startsWith("/") ? path : `/${path}`}`);
}