// @ts-nocheck
import type { ODataModelResponseV4, ODataCollectionResponseV4 } from "@odata2ts/odata-core";
import type { StringCollection } from "@odata2ts/odata-query-objects";
import { QStringCollection, QGuidCollection } from "@odata2ts/odata-query-objects";
import type { ODataHttpClient, ODataHttpClientConfig, HttpResponseModel } from "@odata2ts/http-client-api";
import { ODataService, EntityTypeServiceV4, CollectionServiceV4, EntitySetServiceV4 } from "@odata2ts/odata-service";
import type { ModelId, HealthCheckSettingId, ManufacturerId, LogEntryId, FeedId, TenantId, OsId, Model, EditableModel, HealthCheckSetting, EditableHealthCheckSetting, Manufacturer, EditableManufacturer, LogEntry, EditableLogEntry, TenantLogEntry, EditableTenantLogEntry, FeedLogEntry, EditableFeedLogEntry, Feed, EditableFeed, Tenant, EditableTenant, PolicyBaseId, ActionResult, Tenant_AdminConsentParams, Tenant_ConsentParams, LookupPolicy, RefreshedWrappedArray_1OfDeviceReportEntry, Tenant_ReportParams, Os, EditableOs, OsBuild, EditableOsBuild, PolicyBase, EditablePolicyBase, RefreshCycle, EditableRefreshCycle } from "./JiscM365CsModelsModel";
import type { QModel, QHealthCheckSetting, QManufacturer, QLogEntry, QTenantLogEntry, QFeedLogEntry, QFeed, QTenant, QOs, QOsBuild, QPolicyBase, QRefreshCycle } from "./QJiscM365CsModels";
import { QModelId, QHealthCheckSettingId, QManufacturerId, QLogEntryId, QFeedId, QTenantId, QOsId, qModel, qHealthCheckSetting, qManufacturer, qLogEntry, qTenantLogEntry, qFeedLogEntry, qFeed, Feed_QRefresh, qTenant, QPolicyBaseId, Tenant_QAdminConsent, Tenant_QConsent, Tenant_QLookupPolicy, Tenant_QRefresh, Tenant_QReport, Tenant_QConsents, qOs, qOsBuild, qPolicyBase, qRefreshCycle } from "./QJiscM365CsModels";

export class JiscM365CsModelsService<in out ClientType extends ODataHttpClient> extends ODataService<ClientType> {
    public models(): ModelCollectionService<ClientType>;
    public models(id: ModelId): ModelService<ClientType>;
    public models(id?: ModelId | undefined) {
        const fieldName = "Models";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new ModelCollectionService(client, path, fieldName)
        : new ModelService(client, path, new QModelId(fieldName).buildUrl(id));
    }

    public healthChecks(): HealthCheckSettingCollectionService<ClientType>;
    public healthChecks(id: HealthCheckSettingId): HealthCheckSettingService<ClientType>;
    public healthChecks(id?: HealthCheckSettingId | undefined) {
        const fieldName = "HealthChecks";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new HealthCheckSettingCollectionService(client, path, fieldName)
        : new HealthCheckSettingService(client, path, new QHealthCheckSettingId(fieldName).buildUrl(id));
    }

    public manufacturers(): ManufacturerCollectionService<ClientType>;
    public manufacturers(id: ManufacturerId): ManufacturerService<ClientType>;
    public manufacturers(id?: ManufacturerId | undefined) {
        const fieldName = "Manufacturers";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new ManufacturerCollectionService(client, path, fieldName)
        : new ManufacturerService(client, path, new QManufacturerId(fieldName).buildUrl(id));
    }

    public tenantLogs(): TenantLogEntryCollectionService<ClientType>;
    public tenantLogs(id: LogEntryId): TenantLogEntryService<ClientType>;
    public tenantLogs(id?: LogEntryId | undefined) {
        const fieldName = "TenantLogs";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new TenantLogEntryCollectionService(client, path, fieldName)
        : new TenantLogEntryService(client, path, new QLogEntryId(fieldName).buildUrl(id));
    }

    public feedLogs(): FeedLogEntryCollectionService<ClientType>;
    public feedLogs(id: LogEntryId): FeedLogEntryService<ClientType>;
    public feedLogs(id?: LogEntryId | undefined) {
        const fieldName = "FeedLogs";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new FeedLogEntryCollectionService(client, path, fieldName)
        : new FeedLogEntryService(client, path, new QLogEntryId(fieldName).buildUrl(id));
    }

    public feeds(): FeedCollectionService<ClientType>;
    public feeds(id: FeedId): FeedService<ClientType>;
    public feeds(id?: FeedId | undefined) {
        const fieldName = "Feeds";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new FeedCollectionService(client, path, fieldName)
        : new FeedService(client, path, new QFeedId(fieldName).buildUrl(id));
    }

    public tenants(): TenantCollectionService<ClientType>;
    public tenants(id: TenantId): TenantService<ClientType>;
    public tenants(id?: TenantId | undefined) {
        const fieldName = "Tenants";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new TenantCollectionService(client, path, fieldName)
        : new TenantService(client, path, new QTenantId(fieldName).buildUrl(id));
    }

    public os(): OsCollectionService<ClientType>;
    public os(id: OsId): OsService<ClientType>;
    public os(id?: OsId | undefined) {
        const fieldName = "OS";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new OsCollectionService(client, path, fieldName)
        : new OsService(client, path, new QOsId(fieldName).buildUrl(id));
    }
}

export class ModelService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Model, EditableModel, QModel> {
    private _aliases?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qModel);
    }

    public aliases() {
        if(!this._aliases) {
          const { client, path } = this.__base;
          this._aliases = new CollectionServiceV4(client, path, "aliases", new QStringCollection())
        }

        return this._aliases
    }
}

export class ModelCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Model, EditableModel, QModel, ModelId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qModel, new QModelId(name));
    }
}

export class HealthCheckSettingService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, HealthCheckSetting, EditableHealthCheckSetting, QHealthCheckSetting> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qHealthCheckSetting);
    }
}

export class HealthCheckSettingCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, HealthCheckSetting, EditableHealthCheckSetting, QHealthCheckSetting, HealthCheckSettingId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qHealthCheckSetting, new QHealthCheckSettingId(name));
    }
}

export class ManufacturerService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Manufacturer, EditableManufacturer, QManufacturer> {
    private _aliases?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qManufacturer);
    }

    public aliases() {
        if(!this._aliases) {
          const { client, path } = this.__base;
          this._aliases = new CollectionServiceV4(client, path, "aliases", new QStringCollection())
        }

        return this._aliases
    }
}

export class ManufacturerCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Manufacturer, EditableManufacturer, QManufacturer, ManufacturerId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qManufacturer, new QManufacturerId(name));
    }
}

export class LogEntryService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, LogEntry, EditableLogEntry, QLogEntry> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qLogEntry);
    }
}

export class LogEntryCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, LogEntry, EditableLogEntry, QLogEntry, LogEntryId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qLogEntry, new QLogEntryId(name));
    }
}

export class TenantLogEntryService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, TenantLogEntry, EditableTenantLogEntry, QTenantLogEntry> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qTenantLogEntry);
    }
}

export class TenantLogEntryCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, TenantLogEntry, EditableTenantLogEntry, QTenantLogEntry, LogEntryId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qTenantLogEntry, new QLogEntryId(name));
    }
}

export class FeedLogEntryService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, FeedLogEntry, EditableFeedLogEntry, QFeedLogEntry> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qFeedLogEntry);
    }
}

export class FeedLogEntryCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, FeedLogEntry, EditableFeedLogEntry, QFeedLogEntry, LogEntryId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qFeedLogEntry, new QLogEntryId(name));
    }
}

export class FeedService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Feed, EditableFeed, QFeed> {
    private _manufacturers?: CollectionServiceV4<ClientType, StringCollection, QGuidCollection>;
    private _schedule?: RefreshCycleService<ClientType>;
    private _ignores?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;
    private _feedQRefresh?: Feed_QRefresh;

    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qFeed);
    }

    public manufacturers() {
        if(!this._manufacturers) {
          const { client, path } = this.__base;
          this._manufacturers = new CollectionServiceV4(client, path, "manufacturers", new QGuidCollection())
        }

        return this._manufacturers
    }

    public schedule(): RefreshCycleService<ClientType> {
        if(!this._schedule) {
          const { client, path } = this.__base;
          this._schedule = new RefreshCycleService(client, path, "schedule")
        }

        return this._schedule
    }

    public ignores() {
        if(!this._ignores) {
          const { client, path } = this.__base;
          this._ignores = new CollectionServiceV4(client, path, "ignores", new QStringCollection())
        }

        return this._ignores
    }

    public async refresh(requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
        if(!this._feedQRefresh) {
          this._feedQRefresh = new Feed_QRefresh()
        }

        const { addFullPath, client, getDefaultHeaders } = this.__base;
        const url = addFullPath(this._feedQRefresh.buildUrl());
        return client.post(url, {}, requestConfig, getDefaultHeaders());
        
    }
}

export class FeedCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Feed, EditableFeed, QFeed, FeedId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qFeed, new QFeedId(name));
    }
}

export class TenantService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Tenant, EditableTenant, QTenant> {
    private _updateSchedule?: RefreshCycleService<ClientType>;
    private _deltaSchedule?: RefreshCycleService<ClientType>;
    private _tenantQAdminConsent?: Tenant_QAdminConsent;

    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qTenant);
    }

    public updateSchedule(): RefreshCycleService<ClientType> {
        if(!this._updateSchedule) {
          const { client, path } = this.__base;
          this._updateSchedule = new RefreshCycleService(client, path, "updateSchedule")
        }

        return this._updateSchedule
    }

    public deltaSchedule(): RefreshCycleService<ClientType> {
        if(!this._deltaSchedule) {
          const { client, path } = this.__base;
          this._deltaSchedule = new RefreshCycleService(client, path, "deltaSchedule")
        }

        return this._deltaSchedule
    }

    public policies(): PolicyBaseCollectionService<ClientType>;
    public policies(id: PolicyBaseId): PolicyBaseService<ClientType>;
    public policies(id?: PolicyBaseId | undefined) {
        const fieldName = "policies";
        const { client, path } = this.__base;
        return typeof id === "undefined" || id === null
        ? new PolicyBaseCollectionService(client, path, fieldName)
        : new PolicyBaseService(client, path, new QPolicyBaseId(fieldName).buildUrl(id));
    }

    public async adminConsent(params: Tenant_AdminConsentParams, requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<ActionResult>>> {
        if(!this._tenantQAdminConsent) {
          this._tenantQAdminConsent = new Tenant_QAdminConsent()
        }

        const { addFullPath, client, getDefaultHeaders } = this.__base;
        const url = addFullPath(this._tenantQAdminConsent.buildUrl(params));
        const response = await  client.get(url, requestConfig, getDefaultHeaders());
        return this._tenantQAdminConsent.convertResponse(response);
    }
}

export class TenantCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Tenant, EditableTenant, QTenant, TenantId> {
    private _tenantQConsent?: Tenant_QConsent;
    private _tenantQLookupPolicy?: Tenant_QLookupPolicy;
    private _tenantQRefresh?: Tenant_QRefresh;
    private _tenantQReport?: Tenant_QReport;
    private _tenantQConsents?: Tenant_QConsents;

    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qTenant, new QTenantId(name));
    }

    public async consent(params: Tenant_ConsentParams, requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<ActionResult>>> {
        if(!this._tenantQConsent) {
          this._tenantQConsent = new Tenant_QConsent()
        }

        const { addFullPath, client, getDefaultHeaders } = this.__base;
        const url = addFullPath(this._tenantQConsent.buildUrl(params));
        const response = await  client.get(url, requestConfig, getDefaultHeaders());
        return this._tenantQConsent.convertResponse(response);
    }

    public async lookupPolicy(requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataCollectionResponseV4<LookupPolicy>>> {
        if(!this._tenantQLookupPolicy) {
          this._tenantQLookupPolicy = new Tenant_QLookupPolicy()
        }

        const { addFullPath, client, getDefaultHeaders } = this.__base;
        const url = addFullPath(this._tenantQLookupPolicy.buildUrl());
        const response = await  client.get(url, requestConfig, getDefaultHeaders());
        return this._tenantQLookupPolicy.convertResponse(response);
    }

    public async refresh(requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<void>>> {
        if(!this._tenantQRefresh) {
          this._tenantQRefresh = new Tenant_QRefresh()
        }

        const { addFullPath, client, getDefaultHeaders } = this.__base;
        const url = addFullPath(this._tenantQRefresh.buildUrl());
        return client.post(url, {}, requestConfig, getDefaultHeaders());
        
    }

    public async report(params: Tenant_ReportParams, requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataModelResponseV4<RefreshedWrappedArray_1OfDeviceReportEntry>>> {
        if(!this._tenantQReport) {
          this._tenantQReport = new Tenant_QReport()
        }

        const { addFullPath, client, getDefaultHeaders } = this.__base;
        const url = addFullPath(this._tenantQReport.buildUrl());
        const response = await  client.post(url, this._tenantQReport.convertUserParams(params), requestConfig, getDefaultHeaders());
        return this._tenantQReport.convertResponse(response);
    }

    public async consents(requestConfig?: ODataHttpClientConfig<ClientType>): Promise<HttpResponseModel<ODataCollectionResponseV4<string>>> {
        if(!this._tenantQConsents) {
          this._tenantQConsents = new Tenant_QConsents()
        }

        const { addFullPath, client, getDefaultHeaders } = this.__base;
        const url = addFullPath(this._tenantQConsents.buildUrl());
        const response = await  client.post(url, {}, requestConfig, getDefaultHeaders());
        return this._tenantQConsents.convertResponse(response);
    }
}

export class OsService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, Os, EditableOs, QOs> {
    private _supportedVersions?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;
    private _builds?: CollectionServiceV4<ClientType, OsBuild, QOsBuild, EditableOsBuild>;

    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qOs);
    }

    public supportedVersions() {
        if(!this._supportedVersions) {
          const { client, path } = this.__base;
          this._supportedVersions = new CollectionServiceV4(client, path, "supportedVersions", new QStringCollection())
        }

        return this._supportedVersions
    }

    public builds(): CollectionServiceV4<ClientType, OsBuild, QOsBuild, EditableOsBuild> {
        if(!this._builds) {
          const { client, path } = this.__base;
          this._builds = new CollectionServiceV4(client, path, "builds", qOsBuild)
        }

        return this._builds
    }
}

export class OsCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, Os, EditableOs, QOs, OsId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qOs, new QOsId(name));
    }
}

export class PolicyBaseService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, PolicyBase, EditablePolicyBase, QPolicyBase> {
    private _ignores?: CollectionServiceV4<ClientType, StringCollection, QStringCollection>;

    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qPolicyBase);
    }

    public ignores() {
        if(!this._ignores) {
          const { client, path } = this.__base;
          this._ignores = new CollectionServiceV4(client, path, "ignores", new QStringCollection())
        }

        return this._ignores
    }
}

export class PolicyBaseCollectionService<in out ClientType extends ODataHttpClient> extends EntitySetServiceV4<ClientType, PolicyBase, EditablePolicyBase, QPolicyBase, PolicyBaseId> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qPolicyBase, new QPolicyBaseId(name));
    }
}

export class RefreshCycleService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, RefreshCycle, EditableRefreshCycle, QRefreshCycle> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qRefreshCycle);
    }
}

export class OsBuildService<in out ClientType extends ODataHttpClient> extends EntityTypeServiceV4<ClientType, OsBuild, EditableOsBuild, QOsBuild> {
    constructor(client: ClientType, basePath: string, name: string) {
        super(client, basePath, name, qOsBuild);
    }
}
