// @ts-nocheck
import { QueryObject, QGuidPath, QStringPath, QDateTimeOffsetPath, QEnumPath, QCollectionPath, QStringCollection, QId, QGuidParam, QBooleanPath, QNumberPath, QGuidCollection, QEntityPath, QAction, QEntityCollectionPath, QFunction, OperationReturnType, ReturnTypes, QComplexParam, QStringParam, QEnumParam, QBooleanParam, QBinaryPath } from "@odata2ts/odata-query-objects";
import type { ModelId, HealthCheckSettingId, ManufacturerId, LogEntryId, FeedId, TenantId, Tenant_AdminConsentParams, Tenant_ConsentParams, Tenant_ReportParams, OsId, PolicyBaseId, DeviceReportEntryId, DeviceOwnerId } from "./JiscM365CsModelsModel";
import { OsType, Priority, Impact, NumberCompare, TraceEventType, LogEntrySource, PolicyType } from "./JiscM365CsModelsModel";

export class QModel extends QueryObject {
    public readonly id = new QGuidPath(this.withPrefix("id"));
    public readonly name = new QStringPath(this.withPrefix("name"));
    public readonly description = new QStringPath(this.withPrefix("description"));
    public readonly manufacturer = new QGuidPath(this.withPrefix("manufacturer"));
    public readonly released = new QDateTimeOffsetPath(this.withPrefix("released"));
    public readonly supportEnd = new QDateTimeOffsetPath(this.withPrefix("supportEnd"));
    public readonly type = new QEnumPath(this.withPrefix("type"), OsType);
    public readonly tenant = new QGuidPath(this.withPrefix("tenant"));
    public readonly aliases = new QCollectionPath(this.withPrefix("aliases"), () => QStringCollection);
    public readonly created = new QDateTimeOffsetPath(this.withPrefix("created"));
    public readonly modified = new QDateTimeOffsetPath(this.withPrefix("modified"));
    public readonly createdBy = new QStringPath(this.withPrefix("createdBy"));
    public readonly modifiedBy = new QStringPath(this.withPrefix("modifiedBy"));
}

export const qModel = new QModel();

export class QModelId extends QId<ModelId> {
    private readonly params = [new QGuidParam("id")];

    getParams() {
        return this.params
    }
}

export class QHealthCheckSetting extends QueryObject {
    public readonly id = new QGuidPath(this.withPrefix("id"));
    public readonly name = new QStringPath(this.withPrefix("name"));
    public readonly displayName = new QStringPath(this.withPrefix("displayName"));
    public readonly reason = new QStringPath(this.withPrefix("reason"));
    public readonly priority = new QEnumPath(this.withPrefix("priority"), Priority);
    public readonly impact = new QEnumPath(this.withPrefix("impact"), Impact);
    public readonly moreInfoLink = new QStringPath(this.withPrefix("moreInfoLink"));
    public readonly createdBy = new QStringPath(this.withPrefix("createdBy"));
    public readonly created = new QDateTimeOffsetPath(this.withPrefix("created"));
    public readonly modifiedBy = new QStringPath(this.withPrefix("modifiedBy"));
    public readonly modified = new QDateTimeOffsetPath(this.withPrefix("modified"));
    public readonly category = new QStringPath(this.withPrefix("category"));
}

export const qHealthCheckSetting = new QHealthCheckSetting();

export class QHealthCheckSettingId extends QId<HealthCheckSettingId> {
    private readonly params = [new QGuidParam("id")];

    getParams() {
        return this.params
    }
}

export class QHealthCheckValueSetting extends QHealthCheckSetting {
    public readonly value = new QStringPath(this.withPrefix("value"));
    public readonly not = new QBooleanPath(this.withPrefix("not"));
}

export const qHealthCheckValueSetting = new QHealthCheckValueSetting();

export class QHealthCheckCountSetting extends QHealthCheckSetting {
    public readonly count = new QNumberPath(this.withPrefix("count"));
    public readonly compare = new QEnumPath(this.withPrefix("compare"), NumberCompare);
}

export const qHealthCheckCountSetting = new QHealthCheckCountSetting();

export class QManufacturer extends QueryObject {
    public readonly id = new QGuidPath(this.withPrefix("id"));
    public readonly name = new QStringPath(this.withPrefix("name"));
    public readonly siteUri = new QStringPath(this.withPrefix("siteUri"));
    public readonly aliases = new QCollectionPath(this.withPrefix("aliases"), () => QStringCollection);
    public readonly created = new QDateTimeOffsetPath(this.withPrefix("created"));
    public readonly modified = new QDateTimeOffsetPath(this.withPrefix("modified"));
    public readonly createdBy = new QStringPath(this.withPrefix("createdBy"));
    public readonly modifiedBy = new QStringPath(this.withPrefix("modifiedBy"));
}

export const qManufacturer = new QManufacturer();

export class QManufacturerId extends QId<ManufacturerId> {
    private readonly params = [new QGuidParam("id")];

    getParams() {
        return this.params
    }
}

export class QLogEntry extends QueryObject {
    public readonly id = new QGuidPath(this.withPrefix("id"));
    public readonly parentId = new QGuidPath(this.withPrefix("parentId"));
    public readonly date = new QDateTimeOffsetPath(this.withPrefix("date"));
    public readonly type = new QEnumPath(this.withPrefix("type"), TraceEventType);
    public readonly message = new QStringPath(this.withPrefix("message"));
    public readonly details = new QStringPath(this.withPrefix("details"));
    public readonly source = new QEnumPath(this.withPrefix("source"), LogEntrySource);
}

export const qLogEntry = new QLogEntry();

export class QLogEntryId extends QId<LogEntryId> {
    private readonly params = [new QGuidParam("id")];

    getParams() {
        return this.params
    }
}

export class QTenantLogEntry extends QLogEntry {
}

export const qTenantLogEntry = new QTenantLogEntry();

export class QFeedLogEntry extends QLogEntry {
}

export const qFeedLogEntry = new QFeedLogEntry();

export class QFeed extends QueryObject {
    public readonly enabled = new QBooleanPath(this.withPrefix("enabled"));
    public readonly id = new QGuidPath(this.withPrefix("id"));
    public readonly tenant = new QGuidPath(this.withPrefix("tenant"));
    public readonly name = new QStringPath(this.withPrefix("name"));
    public readonly uri = new QStringPath(this.withPrefix("uri"));
    public readonly manufacturers = new QCollectionPath(this.withPrefix("manufacturers"), () => QGuidCollection);
    public readonly osType = new QEnumPath(this.withPrefix("osType"), OsType);
    public readonly refreshed = new QDateTimeOffsetPath(this.withPrefix("refreshed"));
    public readonly autoEnabled = new QBooleanPath(this.withPrefix("autoEnabled"));
    public readonly schedule = new QEntityPath(this.withPrefix("schedule"), () => QRefreshCycle);
    public readonly created = new QDateTimeOffsetPath(this.withPrefix("created"));
    public readonly createdBy = new QStringPath(this.withPrefix("createdBy"));
    public readonly modified = new QDateTimeOffsetPath(this.withPrefix("modified"));
    public readonly modifiedBy = new QStringPath(this.withPrefix("modifiedBy"));
    public readonly oAuthKey = new QStringPath(this.withPrefix("oAuthKey"));
    public readonly oAuthSecret = new QStringPath(this.withPrefix("oAuthSecret"));
    public readonly addAll = new QBooleanPath(this.withPrefix("addAll"));
    public readonly ignores = new QCollectionPath(this.withPrefix("ignores"), () => QStringCollection);
}

export const qFeed = new QFeed();

export class QFeedId extends QId<FeedId> {
    private readonly params = [new QGuidParam("id")];

    getParams() {
        return this.params
    }
}

export class Feed_QRefresh extends QAction {
    private readonly params: [] = [];

    constructor() {
        super("Default.Refresh")
    }

    getParams() {
        return this.params
    }
}

export class QTenant extends QueryObject {
    public readonly id = new QGuidPath(this.withPrefix("id"));
    public readonly name = new QStringPath(this.withPrefix("name"));
    public readonly isPaidUp = new QBooleanPath(this.withPrefix("isPaidUp"));
    public readonly isPreview = new QBooleanPath(this.withPrefix("isPreview"));
    public readonly hasCe = new QBooleanPath(this.withPrefix("hasCE"));
    public readonly isReadonly = new QBooleanPath(this.withPrefix("isReadonly"));
    public readonly updateAd = new QBooleanPath(this.withPrefix("updateAD"));
    public readonly updateCap = new QBooleanPath(this.withPrefix("updateCAP"));
    public readonly refreshed = new QDateTimeOffsetPath(this.withPrefix("refreshed"));
    public readonly updateSchedule = new QEntityPath(this.withPrefix("updateSchedule"), () => QRefreshCycle);
    public readonly created = new QDateTimeOffsetPath(this.withPrefix("created"));
    public readonly modified = new QDateTimeOffsetPath(this.withPrefix("modified"));
    public readonly devicesDelta = new QStringPath(this.withPrefix("devicesDelta"));
    public readonly deltaSchedule = new QEntityPath(this.withPrefix("deltaSchedule"), () => QRefreshCycle);
    public readonly updateAndroidDeviceNames = new QBooleanPath(this.withPrefix("updateAndroidDeviceNames"));
    public readonly androidDeviceNameFormat = new QStringPath(this.withPrefix("androidDeviceNameFormat"));
    public readonly cleanUpDevices = new QBooleanPath(this.withPrefix("cleanUpDevices"));
    public readonly deviceCleanupAge = new QNumberPath(this.withPrefix("deviceCleanupAge"));
    public readonly deviceWarningWeeks = new QNumberPath(this.withPrefix("deviceWarningWeeks"));
    public readonly osWarningWeeks = new QNumberPath(this.withPrefix("osWarningWeeks"));
    public readonly createdBy = new QStringPath(this.withPrefix("createdBy"));
    public readonly modifiedBy = new QStringPath(this.withPrefix("modifiedBy"));
    public readonly logRetention = new QNumberPath(this.withPrefix("logRetention"));
    public readonly policies = new QEntityCollectionPath(this.withPrefix("policies"), () => QPolicyBase);
}

export const qTenant = new QTenant();

export class QTenantId extends QId<TenantId> {
    private readonly params = [new QGuidParam("id")];

    getParams() {
        return this.params
    }
}

export class Tenant_QAdminConsent extends QFunction<Tenant_AdminConsentParams> {
    private readonly params = [new QStringParam("scopes")];

    constructor() {
        super("Default.AdminConsent", new OperationReturnType(ReturnTypes.COMPLEX, new QComplexParam("NONE", new QActionResult)))
    }

    getParams() {
        return this.params
    }
}

export class Tenant_QConsent extends QFunction<Tenant_ConsentParams> {
    private readonly params = [new QStringParam("scopes")];

    constructor() {
        super("Default.Consent", new OperationReturnType(ReturnTypes.COMPLEX, new QComplexParam("NONE", new QActionResult)))
    }

    getParams() {
        return this.params
    }
}

export class Tenant_QLookupPolicy extends QFunction {
    private readonly params: [] = [];

    constructor() {
        super("Default.LookupPolicy", new OperationReturnType(ReturnTypes.COMPLEX_COLLECTION, new QComplexParam("NONE", new QLookupPolicy)))
    }

    getParams() {
        return this.params
    }

    buildUrl() {
        return super.buildUrl(undefined)
    }
}

export class Tenant_QRefresh extends QAction {
    private readonly params: [] = [];

    constructor() {
        super("Default.Refresh")
    }

    getParams() {
        return this.params
    }
}

export class Tenant_QReport extends QAction<Tenant_ReportParams> {
    private readonly params = [new QEnumParam("Mode", "mode"),new QBooleanParam("ShowDisabled", "showDisabled")];

    constructor() {
        super("Default.Report", new OperationReturnType(ReturnTypes.COMPLEX, new QComplexParam("NONE", new QRefreshedWrappedArray_1OfDeviceReportEntry)))
    }

    getParams() {
        return this.params
    }
}

export class Tenant_QConsents extends QAction {
    private readonly params: [] = [];

    constructor() {
        super("Default.Consents")
    }

    getParams() {
        return this.params
    }
}

export class QOs extends QueryObject {
    public readonly id = new QStringPath(this.withPrefix("id"));
    public readonly name = new QStringPath(this.withPrefix("name"));
    public readonly supportedVersions = new QCollectionPath(this.withPrefix("supportedVersions"), () => QStringCollection);
    public readonly builds = new QEntityCollectionPath(this.withPrefix("builds"), () => QOsBuild);
    public readonly refreshed = new QDateTimeOffsetPath(this.withPrefix("refreshed"));
    public readonly createdBy = new QStringPath(this.withPrefix("createdBy"));
    public readonly created = new QDateTimeOffsetPath(this.withPrefix("created"));
    public readonly modifiedBy = new QStringPath(this.withPrefix("modifiedBy"));
    public readonly modified = new QDateTimeOffsetPath(this.withPrefix("modified"));
}

export const qOs = new QOs();

export class QOsId extends QId<OsId> {
    private readonly params = [new QStringParam("id")];

    getParams() {
        return this.params
    }
}

export class QPolicyBase extends QueryObject {
    public readonly id = new QStringPath(this.withPrefix("id"));
    public readonly displayName = new QStringPath(this.withPrefix("displayName"));
    public readonly policyType = new QEnumPath(this.withPrefix("policyType"), PolicyType);
    public readonly ignores = new QCollectionPath(this.withPrefix("ignores"), () => QStringCollection);
    public readonly delay = new QNumberPath(this.withPrefix("delay"));
    public readonly warnDuringDelay = new QBooleanPath(this.withPrefix("warnDuringDelay"));
}

export const qPolicyBase = new QPolicyBase();

export class QPolicyBaseId extends QId<PolicyBaseId> {
    private readonly params = [new QStringParam("id")];

    getParams() {
        return this.params
    }
}

export class QPolicy extends QPolicyBase {
}

export const qPolicy = new QPolicy();

export class QWindowsPolicy extends QPolicyBase {
    public readonly allowWindows10 = new QBooleanPath(this.withPrefix("allowWindows10"));
}

export const qWindowsPolicy = new QWindowsPolicy();

export class QAndroidPolicy extends QPolicyBase {
    public readonly patchMonths = new QNumberPath(this.withPrefix("patchMonths"));
}

export const qAndroidPolicy = new QAndroidPolicy();

export class QDeadlinePolicy extends QPolicyBase {
    public readonly deadline = new QNumberPath(this.withPrefix("deadline"));
}

export const qDeadlinePolicy = new QDeadlinePolicy();

export class QDeviceReportEntry extends QueryObject {
    public readonly id = new QStringPath(this.withPrefix("id"));
}

export const qDeviceReportEntry = new QDeviceReportEntry();

export class QDeviceReportEntryId extends QId<DeviceReportEntryId> {
    private readonly params = [new QStringParam("id")];

    getParams() {
        return this.params
    }
}

export class QCeDeviceGroupKey extends QDeviceReportEntry {
    public readonly manufacturer = new QStringPath(this.withPrefix("manufacturer"));
    public readonly os = new QStringPath(this.withPrefix("os"));
    public readonly osVersion = new QStringPath(this.withPrefix("osVersion"));
    public readonly count = new QNumberPath(this.withPrefix("count"));
}

export const qCeDeviceGroupKey = new QCeDeviceGroupKey();

export class QCeosGroupKey extends QDeviceReportEntry {
    public readonly os = new QStringPath(this.withPrefix("os"));
    public readonly osVersion = new QStringPath(this.withPrefix("osVersion"));
    public readonly ownership = new QStringPath(this.withPrefix("ownership"));
    public readonly released = new QDateTimeOffsetPath(this.withPrefix("released"));
    public readonly expires = new QDateTimeOffsetPath(this.withPrefix("expires"));
    public readonly devices = new QEntityCollectionPath(this.withPrefix("devices"), () => QCeDevice);
}

export const qCeosGroupKey = new QCeosGroupKey();

export class QCeooddKey extends QDeviceReportEntry {
    public readonly manufacturer = new QStringPath(this.withPrefix("manufacturer"));
    public readonly model = new QStringPath(this.withPrefix("model"));
    public readonly ownership = new QStringPath(this.withPrefix("ownership"));
    public readonly released = new QDateTimeOffsetPath(this.withPrefix("released"));
    public readonly expires = new QDateTimeOffsetPath(this.withPrefix("expires"));
    public readonly devices = new QEntityCollectionPath(this.withPrefix("devices"), () => QCeDevice);
}

export const qCeooddKey = new QCeooddKey();

export class QStaleDeviceKey extends QDeviceReportEntry {
    public readonly date = new QDateTimeOffsetPath(this.withPrefix("date"));
    public readonly devices = new QEntityCollectionPath(this.withPrefix("devices"), () => QCeDevice);
}

export const qStaleDeviceKey = new QStaleDeviceKey();

export class QCeDevice extends QDeviceReportEntry {
    public readonly deviceId = new QStringPath(this.withPrefix("deviceId"));
    public readonly displayName = new QStringPath(this.withPrefix("displayName"));
    public readonly supportEnd = new QStringPath(this.withPrefix("supportEnd"));
    public readonly isEoL = new QStringPath(this.withPrefix("isEoL"));
    public readonly manufacturer = new QStringPath(this.withPrefix("manufacturer"));
    public readonly model = new QStringPath(this.withPrefix("model"));
    public readonly ownership = new QStringPath(this.withPrefix("ownership"));
    public readonly os = new QStringPath(this.withPrefix("os"));
    public readonly osVersion = new QStringPath(this.withPrefix("osVersion"));
    public readonly profileType = new QStringPath(this.withPrefix("profileType"));
    public readonly isRooted = new QBooleanPath(this.withPrefix("isRooted"));
    public readonly approximateLastSignInDateTime = new QStringPath(this.withPrefix("approximateLastSignInDateTime"));
    public readonly managementType = new QStringPath(this.withPrefix("managementType"));
    public readonly released = new QStringPath(this.withPrefix("released"));
    public readonly registrationDateTime = new QStringPath(this.withPrefix("registrationDateTime"));
    public readonly trustType = new QStringPath(this.withPrefix("trustType"));
    public readonly enabled = new QBooleanPath(this.withPrefix("enabled"));
    public readonly owners = new QEntityCollectionPath(this.withPrefix("owners"), () => QDeviceOwner);
}

export const qCeDevice = new QCeDevice();

export class QDeviceOwner extends QueryObject {
    public readonly id = new QStringPath(this.withPrefix("id"));
    public readonly displayName = new QStringPath(this.withPrefix("displayName"));
    public readonly mail = new QStringPath(this.withPrefix("mail"));
    public readonly department = new QStringPath(this.withPrefix("department"));
    public readonly accountEnabled = new QBooleanPath(this.withPrefix("accountEnabled"));
}

export const qDeviceOwner = new QDeviceOwner();

export class QDeviceOwnerId extends QId<DeviceOwnerId> {
    private readonly params = [new QStringParam("id")];

    getParams() {
        return this.params
    }
}

export class QRefreshCycle extends QueryObject {
    public readonly start = new QDateTimeOffsetPath(this.withPrefix("start"));
    public readonly repeat = new QStringPath(this.withPrefix("repeat"));
}

export const qRefreshCycle = new QRefreshCycle();

export class QOsBuild extends QueryObject {
    public readonly build = new QStringPath(this.withPrefix("build"));
    public readonly released = new QDateTimeOffsetPath(this.withPrefix("released"));
    public readonly expires = new QDateTimeOffsetPath(this.withPrefix("expires"));
}

export const qOsBuild = new QOsBuild();

export class QLookupPolicy extends QueryObject {
    public readonly id = new QStringPath(this.withPrefix("id"));
    public readonly displayName = new QStringPath(this.withPrefix("displayName"));
    public readonly type = new QEnumPath(this.withPrefix("type"), PolicyType);
}

export const qLookupPolicy = new QLookupPolicy();

export class QRefreshedWrappedArray_1OfDeviceReportEntry extends QueryObject {
    public readonly refreshed = new QDateTimeOffsetPath(this.withPrefix("refreshed"));
    public readonly value = new QEntityCollectionPath(this.withPrefix("value"), () => QDeviceReportEntry);
}

export const qRefreshedWrappedArray_1OfDeviceReportEntry = new QRefreshedWrappedArray_1OfDeviceReportEntry();

export class QActionResult extends QueryObject {
}

export const qActionResult = new QActionResult();

export class QChallengeResult extends QActionResult {
    public readonly authenticationSchemes = new QCollectionPath(this.withPrefix("authenticationSchemes"), () => QStringCollection);
    public readonly properties = new QEntityPath(this.withPrefix("properties"), () => QAuthenticationProperties);
}

export const qChallengeResult = new QChallengeResult();

export class QContentResult extends QActionResult {
    public readonly content = new QStringPath(this.withPrefix("content"));
    public readonly contentType = new QStringPath(this.withPrefix("contentType"));
    public readonly statusCode = new QNumberPath(this.withPrefix("statusCode"));
}

export const qContentResult = new QContentResult();

export class QEmptyResult extends QActionResult {
}

export const qEmptyResult = new QEmptyResult();

export class QFileResult extends QActionResult {
    public readonly fileDownloadName = new QStringPath(this.withPrefix("fileDownloadName"));
    public readonly lastModified = new QDateTimeOffsetPath(this.withPrefix("lastModified"));
    public readonly entityTag = new QEntityPath(this.withPrefix("entityTag"), () => QEntityTagHeaderValue);
    public readonly enableRangeProcessing = new QBooleanPath(this.withPrefix("enableRangeProcessing"));
}

export const qFileResult = new QFileResult();

export class QFileContentResult extends QFileResult {
    public readonly fileContents = new QBinaryPath(this.withPrefix("fileContents"));
}

export const qFileContentResult = new QFileContentResult();

export class QFileStreamResult extends QFileResult {
    public readonly fileStream = new QStringPath(this.withPrefix("fileStream"));
}

export const qFileStreamResult = new QFileStreamResult();

export class QPhysicalFileResult extends QFileResult {
    public readonly fileName = new QStringPath(this.withPrefix("fileName"));
}

export const qPhysicalFileResult = new QPhysicalFileResult();

export class QVirtualFileResult extends QFileResult {
    public readonly fileName = new QStringPath(this.withPrefix("fileName"));
    public readonly fileProvider = new QEntityPath(this.withPrefix("fileProvider"), () => QIFileProvider);
}

export const qVirtualFileResult = new QVirtualFileResult();

export class QForbidResult extends QActionResult {
    public readonly authenticationSchemes = new QCollectionPath(this.withPrefix("authenticationSchemes"), () => QStringCollection);
    public readonly properties = new QEntityPath(this.withPrefix("properties"), () => QAuthenticationProperties);
}

export const qForbidResult = new QForbidResult();

export class QJsonResult extends QActionResult {
    public readonly contentType = new QStringPath(this.withPrefix("contentType"));
    public readonly serializerSettings = new QStringPath(this.withPrefix("serializerSettings"));
    public readonly statusCode = new QNumberPath(this.withPrefix("statusCode"));
    public readonly value = new QStringPath(this.withPrefix("value"));
}

export const qJsonResult = new QJsonResult();

export class QLocalRedirectResult extends QActionResult {
    public readonly permanent = new QBooleanPath(this.withPrefix("permanent"));
    public readonly preserveMethod = new QBooleanPath(this.withPrefix("preserveMethod"));
    public readonly url = new QStringPath(this.withPrefix("url"));
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
}

export const qLocalRedirectResult = new QLocalRedirectResult();

export class QIUrlHelper extends QueryObject {
}

export const qIUrlHelper = new QIUrlHelper();

export class QObjectResult extends QActionResult {
    public readonly value = new QStringPath(this.withPrefix("value"));
    public readonly formatters = new QEntityCollectionPath(this.withPrefix("formatters"), () => QIOutputFormatter);
    public readonly contentTypes = new QCollectionPath(this.withPrefix("contentTypes"), () => QStringCollection);
    public readonly declaredType = new QEntityPath(this.withPrefix("declaredType"), () => QType);
    public readonly statusCode = new QNumberPath(this.withPrefix("statusCode"));
}

export const qObjectResult = new QObjectResult();

export class QAcceptedAtActionResult extends QObjectResult {
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
    public readonly actionName = new QStringPath(this.withPrefix("actionName"));
    public readonly controllerName = new QStringPath(this.withPrefix("controllerName"));
}

export const qAcceptedAtActionResult = new QAcceptedAtActionResult();

export class QAcceptedAtRouteResult extends QObjectResult {
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
    public readonly routeName = new QStringPath(this.withPrefix("routeName"));
}

export const qAcceptedAtRouteResult = new QAcceptedAtRouteResult();

export class QAcceptedResult extends QObjectResult {
    public readonly location = new QStringPath(this.withPrefix("location"));
}

export const qAcceptedResult = new QAcceptedResult();

export class QBadRequestObjectResult extends QObjectResult {
}

export const qBadRequestObjectResult = new QBadRequestObjectResult();

export class QConflictObjectResult extends QObjectResult {
}

export const qConflictObjectResult = new QConflictObjectResult();

export class QCreatedAtActionResult extends QObjectResult {
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
    public readonly actionName = new QStringPath(this.withPrefix("actionName"));
    public readonly controllerName = new QStringPath(this.withPrefix("controllerName"));
}

export const qCreatedAtActionResult = new QCreatedAtActionResult();

export class QCreatedAtRouteResult extends QObjectResult {
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
    public readonly routeName = new QStringPath(this.withPrefix("routeName"));
}

export const qCreatedAtRouteResult = new QCreatedAtRouteResult();

export class QCreatedResult extends QObjectResult {
    public readonly location = new QStringPath(this.withPrefix("location"));
}

export const qCreatedResult = new QCreatedResult();

export class QNotFoundObjectResult extends QObjectResult {
}

export const qNotFoundObjectResult = new QNotFoundObjectResult();

export class QOkObjectResult extends QObjectResult {
}

export const qOkObjectResult = new QOkObjectResult();

export class QUnauthorizedObjectResult extends QObjectResult {
}

export const qUnauthorizedObjectResult = new QUnauthorizedObjectResult();

export class QUnprocessableEntityObjectResult extends QObjectResult {
}

export const qUnprocessableEntityObjectResult = new QUnprocessableEntityObjectResult();

export class QRedirectResult extends QActionResult {
    public readonly permanent = new QBooleanPath(this.withPrefix("permanent"));
    public readonly preserveMethod = new QBooleanPath(this.withPrefix("preserveMethod"));
    public readonly url = new QStringPath(this.withPrefix("url"));
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
}

export const qRedirectResult = new QRedirectResult();

export class QRedirectToActionResult extends QActionResult {
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
    public readonly actionName = new QStringPath(this.withPrefix("actionName"));
    public readonly controllerName = new QStringPath(this.withPrefix("controllerName"));
    public readonly permanent = new QBooleanPath(this.withPrefix("permanent"));
    public readonly preserveMethod = new QBooleanPath(this.withPrefix("preserveMethod"));
    public readonly fragment = new QStringPath(this.withPrefix("fragment"));
}

export const qRedirectToActionResult = new QRedirectToActionResult();

export class QRedirectToPageResult extends QActionResult {
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
    public readonly pageName = new QStringPath(this.withPrefix("pageName"));
    public readonly pageHandler = new QStringPath(this.withPrefix("pageHandler"));
    public readonly permanent = new QBooleanPath(this.withPrefix("permanent"));
    public readonly preserveMethod = new QBooleanPath(this.withPrefix("preserveMethod"));
    public readonly fragment = new QStringPath(this.withPrefix("fragment"));
    public readonly protocol = new QStringPath(this.withPrefix("protocol"));
    public readonly host = new QStringPath(this.withPrefix("host"));
}

export const qRedirectToPageResult = new QRedirectToPageResult();

export class QRedirectToRouteResult extends QActionResult {
    public readonly urlHelper = new QEntityPath(this.withPrefix("urlHelper"), () => QIUrlHelper);
    public readonly routeName = new QStringPath(this.withPrefix("routeName"));
    public readonly permanent = new QBooleanPath(this.withPrefix("permanent"));
    public readonly preserveMethod = new QBooleanPath(this.withPrefix("preserveMethod"));
    public readonly fragment = new QStringPath(this.withPrefix("fragment"));
}

export const qRedirectToRouteResult = new QRedirectToRouteResult();

export class QSignInResult extends QActionResult {
    public readonly authenticationScheme = new QStringPath(this.withPrefix("authenticationScheme"));
    public readonly principal = new QEntityPath(this.withPrefix("principal"), () => QClaimsPrincipal);
    public readonly properties = new QEntityPath(this.withPrefix("properties"), () => QAuthenticationProperties);
}

export const qSignInResult = new QSignInResult();

export class QSignOutResult extends QActionResult {
    public readonly authenticationSchemes = new QCollectionPath(this.withPrefix("authenticationSchemes"), () => QStringCollection);
    public readonly properties = new QEntityPath(this.withPrefix("properties"), () => QAuthenticationProperties);
}

export const qSignOutResult = new QSignOutResult();

export class QStatusCodeResult extends QActionResult {
}

export const qStatusCodeResult = new QStatusCodeResult();

export class QBadRequestResult extends QStatusCodeResult {
}

export const qBadRequestResult = new QBadRequestResult();

export class QAntiforgeryValidationFailedResult extends QBadRequestResult {
}

export const qAntiforgeryValidationFailedResult = new QAntiforgeryValidationFailedResult();

export class QConflictResult extends QStatusCodeResult {
}

export const qConflictResult = new QConflictResult();

export class QNoContentResult extends QStatusCodeResult {
}

export const qNoContentResult = new QNoContentResult();

export class QNotFoundResult extends QStatusCodeResult {
}

export const qNotFoundResult = new QNotFoundResult();

export class QOkResult extends QStatusCodeResult {
}

export const qOkResult = new QOkResult();

export class QUnauthorizedResult extends QStatusCodeResult {
}

export const qUnauthorizedResult = new QUnauthorizedResult();

export class QUnprocessableEntityResult extends QStatusCodeResult {
}

export const qUnprocessableEntityResult = new QUnprocessableEntityResult();

export class QUnsupportedMediaTypeResult extends QStatusCodeResult {
}

export const qUnsupportedMediaTypeResult = new QUnsupportedMediaTypeResult();

export class QAuthenticationProperties extends QueryObject {
    public readonly items = new QEntityCollectionPath(this.withPrefix("items"), () => QKeyValuePair_2OfStringString);
    public readonly isPersistent = new QBooleanPath(this.withPrefix("isPersistent"));
    public readonly redirectUri = new QStringPath(this.withPrefix("redirectUri"));
    public readonly issuedUtc = new QDateTimeOffsetPath(this.withPrefix("issuedUtc"));
    public readonly expiresUtc = new QDateTimeOffsetPath(this.withPrefix("expiresUtc"));
    public readonly allowRefresh = new QBooleanPath(this.withPrefix("allowRefresh"));
}

export const qAuthenticationProperties = new QAuthenticationProperties();

export class QKeyValuePair_2OfStringString extends QueryObject {
}

export const qKeyValuePair_2OfStringString = new QKeyValuePair_2OfStringString();

export class QEntityTagHeaderValue extends QueryObject {
}

export const qEntityTagHeaderValue = new QEntityTagHeaderValue();

export class QIFileProvider extends QueryObject {
}

export const qIFileProvider = new QIFileProvider();

export class QIOutputFormatter extends QueryObject {
}

export const qIOutputFormatter = new QIOutputFormatter();

export class QMemberInfo extends QueryObject {
    public readonly customAttributes = new QEntityCollectionPath(this.withPrefix("customAttributes"), () => QCustomAttributeData);
}

export const qMemberInfo = new QMemberInfo();

export class QCustomAttributeData extends QueryObject {
    public readonly constructorArguments = new QEntityCollectionPath(this.withPrefix("constructorArguments"), () => QCustomAttributeTypedArgument);
    public readonly namedArguments = new QEntityCollectionPath(this.withPrefix("namedArguments"), () => QCustomAttributeNamedArgument);
}

export const qCustomAttributeData = new QCustomAttributeData();

export class QCustomAttributeTypedArgument extends QueryObject {
}

export const qCustomAttributeTypedArgument = new QCustomAttributeTypedArgument();

export class QCustomAttributeNamedArgument extends QueryObject {
}

export const qCustomAttributeNamedArgument = new QCustomAttributeNamedArgument();

export class QType extends QMemberInfo {
    public readonly genericTypeArguments = new QEntityCollectionPath(this.withPrefix("genericTypeArguments"), () => QType);
}

export const qType = new QType();

export class QTypeInfo extends QType {
    public readonly genericTypeParameters = new QEntityCollectionPath(this.withPrefix("genericTypeParameters"), () => QType);
    public readonly declaredConstructors = new QEntityCollectionPath(this.withPrefix("declaredConstructors"), () => QConstructorInfo);
    public readonly declaredEvents = new QEntityCollectionPath(this.withPrefix("declaredEvents"), () => QEventInfo);
    public readonly declaredFields = new QEntityCollectionPath(this.withPrefix("declaredFields"), () => QFieldInfo);
    public readonly declaredMembers = new QEntityCollectionPath(this.withPrefix("declaredMembers"), () => QMemberInfo);
    public readonly declaredMethods = new QEntityCollectionPath(this.withPrefix("declaredMethods"), () => QMethodInfo);
    public readonly declaredNestedTypes = new QEntityCollectionPath(this.withPrefix("declaredNestedTypes"), () => QTypeInfo);
    public readonly declaredProperties = new QEntityCollectionPath(this.withPrefix("declaredProperties"), () => QPropertyInfo);
    public readonly implementedInterfaces = new QEntityCollectionPath(this.withPrefix("implementedInterfaces"), () => QType);
}

export const qTypeInfo = new QTypeInfo();

export class QMethodBase extends QMemberInfo {
}

export const qMethodBase = new QMethodBase();

export class QConstructorInfo extends QMethodBase {
}

export const qConstructorInfo = new QConstructorInfo();

export class QEventInfo extends QMemberInfo {
}

export const qEventInfo = new QEventInfo();

export class QFieldInfo extends QMemberInfo {
}

export const qFieldInfo = new QFieldInfo();

export class QPropertyInfo extends QMemberInfo {
}

export const qPropertyInfo = new QPropertyInfo();

export class QMethodInfo extends QMethodBase {
}

export const qMethodInfo = new QMethodInfo();

export class QTypeDelegator extends QTypeInfo {
}

export const qTypeDelegator = new QTypeDelegator();

export class QGenericTypeParameterBuilder extends QTypeInfo {
}

export const qGenericTypeParameterBuilder = new QGenericTypeParameterBuilder();

export class QConstructorBuilder extends QConstructorInfo {
    public readonly initLocals = new QBooleanPath(this.withPrefix("initLocals"));
}

export const qConstructorBuilder = new QConstructorBuilder();

export class QFieldBuilder extends QFieldInfo {
}

export const qFieldBuilder = new QFieldBuilder();

export class QPropertyBuilder extends QPropertyInfo {
}

export const qPropertyBuilder = new QPropertyBuilder();

export class QMethodBuilder extends QMethodInfo {
    public readonly initLocals = new QBooleanPath(this.withPrefix("initLocals"));
}

export const qMethodBuilder = new QMethodBuilder();

export class QDynamicMethod extends QMethodInfo {
    public readonly initLocals = new QBooleanPath(this.withPrefix("initLocals"));
}

export const qDynamicMethod = new QDynamicMethod();

export class QEnumBuilder extends QTypeInfo {
}

export const qEnumBuilder = new QEnumBuilder();

export class QTypeBuilder extends QTypeInfo {
}

export const qTypeBuilder = new QTypeBuilder();

export class QComAwareEventInfo extends QEventInfo {
}

export const qComAwareEventInfo = new QComAwareEventInfo();

export class QCreatedODataResult_1OfT extends QObjectResult {
}

export const qCreatedODataResult_1OfT = new QCreatedODataResult_1OfT();

export class QUpdatedODataResult_1OfT extends QObjectResult {
}

export const qUpdatedODataResult_1OfT = new QUpdatedODataResult_1OfT();

export class QBadRequestODataResult extends QBadRequestResult {
}

export const qBadRequestODataResult = new QBadRequestODataResult();

export class QConflictODataResult extends QConflictResult {
}

export const qConflictODataResult = new QConflictODataResult();

export class QNotFoundODataResult extends QNotFoundResult {
}

export const qNotFoundODataResult = new QNotFoundODataResult();

export class QUnauthorizedODataResult extends QUnauthorizedResult {
}

export const qUnauthorizedODataResult = new QUnauthorizedODataResult();

export class QUnprocessableEntityODataResult extends QUnprocessableEntityResult {
}

export const qUnprocessableEntityODataResult = new QUnprocessableEntityODataResult();

export class QODataErrorResult extends QActionResult {
}

export const qODataErrorResult = new QODataErrorResult();

export class QClaimsPrincipal extends QueryObject {
    public readonly claims = new QEntityCollectionPath(this.withPrefix("claims"), () => QClaim);
    public readonly identities = new QEntityCollectionPath(this.withPrefix("identities"), () => QClaimsIdentity);
}

export const qClaimsPrincipal = new QClaimsPrincipal();

export class QClaim extends QueryObject {
    public readonly properties = new QEntityCollectionPath(this.withPrefix("properties"), () => QKeyValuePair_2OfStringString);
}

export const qClaim = new QClaim();

export class QClaimsIdentity extends QueryObject {
    public readonly actor = new QEntityPath(this.withPrefix("actor"), () => QClaimsIdentity);
    public readonly bootstrapContext = new QStringPath(this.withPrefix("bootstrapContext"));
    public readonly claims = new QEntityCollectionPath(this.withPrefix("claims"), () => QClaim);
    public readonly label = new QStringPath(this.withPrefix("label"));
}

export const qClaimsIdentity = new QClaimsIdentity();

export class QGenericIdentity extends QClaimsIdentity {
}

export const qGenericIdentity = new QGenericIdentity();

export class QGenericPrincipal extends QClaimsPrincipal {
}

export const qGenericPrincipal = new QGenericPrincipal();
